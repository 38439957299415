<template>
  <div>
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form">
        <!-- 搜索栏 -->
        <a-row>

          <a-form-item label="模块名称" name="name" class="ui-form__item">
            <a-input v-model:value="formState.name" placeholder="请输入模块名称"></a-input>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
            <a-button v-permission="['cinema_policy_pricing_add']" type="primary" @click="handleAdd">新增模块</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button
              style="margin-right: 10px"
              type="primary"
              html-type="submit"
            >搜索</a-button
            >
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>

      <div style="margin-top: 20px;">
        <a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
                 :scroll="{ x: 950 }">
          <template #bodyCell="{ column, text, record }">
            <template v-if="column.key === 'name'">
              <a-input style="width: 200px;" v-model:value="record.name"></a-input>
            </template>

            <template v-if="column.key === 'priority'">
              <a-input-number style="width: 100px;" v-model:value="record.priority"></a-input-number>
            </template>

            <template v-if="column.key === 'action'">
              <a-dropdown :trigger="['click', 'hover']">
                <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div v-if="text.name !== '常见问题'">
                      <a-menu-item>
                        编辑
                      </a-menu-item>
                    </div>
                    <div>
                      <a-menu-item>
                        启用/禁用
                      </a-menu-item>
                    </div>
                    <div v-if="text.name !== '常见问题'">
                      <a-menu-item>
                        删除
                      </a-menu-item>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </template>
        </a-table>
      </div>
    </a-spin>

  </div>
</template>

<script>
import {
  Icon
} from '@/components/icon/icon.js';
export default {
  components: {
    Icon
  },
  data() {
    return {
      count: 3,
      showModal: false,
      isEdit: false,
      isSee: false,
      id: 0,
      loading: false,
      showAll: false,
      formState: {
        name: '',
        time: [],
        couponType: 2,
        // organizationId: 0,
        status: 0
      },
      columns: [{
        title: '模块名称',
        key: 'name',
        width: 100
      }, {
        title: '优先级',
        key: 'priority',
        width: 100
      }, {
        title: '状态',
        dataIndex: 'state',
        width: 100
      }, {
        title: '操作',
        key: 'action',
        width: 50
      }],
      list: [{
        name: '常见问题',
        priority: '1',
        state: '启用',
      }, {
        name: '模块1',
        priority: '2',
        state: '启用',
      }, {
        name: '模块2',
        priority: '3',
        state: '启用',
      }],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  created() {
  },
  methods: {
    reset() {
      this.$refs.form.resetFields();
      this.time = [];
      this.time1 = [];
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getExchangeInfoList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          couponType: 2,
          ...this.searchData
        });
        this.loading = false;
        if (ret.code === 200) {
          this.pagination.total = ret.data.totalCount;
          this.list = ret.data.list;
        }
      } catch (e) {
        this.loading = false;
      }
    },
    onSend() {
      this.id = 0;
      this.isEdit = false;
      this.isSee = false;
      this.showModal = true;
    },
    onBack(isRef) {
      this.showModal = false;
      if (isRef) {
        this.$nextTick(() => {
          this.getData();
        })
      }
    },
    handleAdd() {
      const list = this.list
      const count = this.count
      const newData = {
        name: '',
        priority: '',
        state: '禁用',
      };
      this.list = [...list, newData];
      this.count = count + 1;
    }
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
